const portfolioItems = [
  {
    title: "Kanban{ish}",
    sinopsis: "Simplified version of a classic Kanban board. Click to open",
    desc:
      "Simple Kanban board to arrange cards. Built using MERN stack (Mongo, Express/Node, React, and Redux). ",
    img: "./portfolio/kanban.png",
    live: "https://kanbanish-in-react.herokuapp.com/",
    repo: "https://github.com/SirMattCam/kanbanish-on-heroku",
    idx: 1,
  },
  {
    title: "Stocks Application (ReactJS version)",
    sinopsis:
      "POC stock trading application built using React. Open up for more details",
    desc:
      "A POC application to buy/sell stocks, stream live stock prices, review recent stock transactions, and view historic graph stock price data. Built with ReactJS and Highcharts. Web sockets being used for live streaming of ticker prices.",
    img: "./portfolio/stocks.png",
    repo: "https://github.com/SirMattCam/stocks-trading-react",
    idx: 2,
  },
  {
    title: "Stocks Application (Angular version)",
    sinopsis:
      "POC stock trading application built using Angular. Open up for more details",
    desc:
      "A POC application to buy/sell stocks, stream live stock prices, review recent stock transactions, and view historic graph stock price data. Built with Angular and Highcharts. Web sockets being used for live streaming of ticker prices.",
    img: "./portfolio/stocks.png",
    repo: "https://github.com/SirMattCam/stocks-trading-angular",
    idx: 2,
  },
]

export default portfolioItems
